import { template as template_4a7c2ee7264e4bc5a0e708f689130568 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4a7c2ee7264e4bc5a0e708f689130568(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
