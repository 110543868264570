import { template as template_a4122c92b6314e3cabbd16721acb98e4 } from "@ember/template-compiler";
const WelcomeHeader = template_a4122c92b6314e3cabbd16721acb98e4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
