import { template as template_4fd7986453f54ee79a456d438924a925 } from "@ember/template-compiler";
const FKText = template_4fd7986453f54ee79a456d438924a925(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
