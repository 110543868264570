import { template as template_2f38ab0b313540d68b1c666b6d88087f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_2f38ab0b313540d68b1c666b6d88087f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
