import { template as template_edd9f8d9899e40d2854aa53f68b1c1ab } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_edd9f8d9899e40d2854aa53f68b1c1ab(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
